import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import menus from "../../pages/menu";
import logo from "../../assets/images/logo/logo.png";
import "./styles.scss";

const Header = () => {
  const [isFixed, setIsFixed] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const headerRef = useRef(null);
  const menuTimeoutRef = useRef(null);

  useEffect(() => {
    const header = headerRef.current;
    const observerOptions = {
      root: null,
      rootMargin: "-300px 0px 0px 0px", 
      threshold: [0, 1]
    };

    const observerCallback = (entries) => {
      const [entry] = entries;
      setIsFixed(!entry.isIntersecting);
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    
    if (header) {
      observer.observe(header);
    }

    return () => {
      if (header) {
        observer.unobserve(header);
      }
    };
  }, []);

  const handleMenuActive = useCallback(() => {
    setMenuActive(prev => !prev);
    setActiveIndex(null);

    if (menuTimeoutRef.current) {
      clearTimeout(menuTimeoutRef.current);
    }

    if (!menuActive) {
      menuTimeoutRef.current = setTimeout(() => {
        setMenuActive(false);
      }, 2500);
    }
  }, [menuActive]);

  const handleDropdown = useCallback((index) => {
    setActiveIndex(prev => prev === index ? null : index);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".menu-item")) {
        setActiveIndex(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const resetInactivityTimer = useCallback(() => {
    if (menuTimeoutRef.current) {
      clearTimeout(menuTimeoutRef.current);
    }
    menuTimeoutRef.current = setTimeout(() => {
      setMenuActive(false);
    }, 2500);
  }, []);

  const renderMenuItems = useCallback((items, parentIndex = null) => {
    return items.map((data, idx) => {
      const currentIndex = parentIndex !== null ? `${parentIndex}-${idx}` : `${idx}`;

      return (
        <li
          key={currentIndex}
          onClick={() => {
            handleDropdown(currentIndex);
            resetInactivityTimer();
          }}
          className={`menu-item ${
            data.namesub ? "menu-item-has-children" : ""
          } ${activeIndex === currentIndex ? "active" : ""}`}
          style={{ color: 'black' }}
        >
          <Link 
            to={data.links} 
            className="menu-link"
            style={{ 
              color: 'black', 
              textDecoration: 'none',
              display: 'block',
              padding: '10px 15px'
            }}
          >
            {data.name || data.sub}
          </Link>
          {data.namesub && (
            <ul className="sub-menu" style={{ color: 'black' }}>
              {renderMenuItems(data.namesub, currentIndex)}
            </ul>
          )}
        </li>
      );
    });
  }, [activeIndex, handleDropdown, resetInactivityTimer]);

  return (
    <header
      ref={headerRef}
      id="header_main"
      className={`header ${isFixed ? "is-fixed" : ""}`}
      style={{ backgroundColor: 'white' }}
    >
      <div className="container big">
        <div className="row">
          <div className="col-12">
            <div className="header__body">
              <div className="header__logo">
                <Link to="/">
                  <img
                    style={{
                      height: "auto",
                      width: "200px",
                    }}
                    id="site-logo"
                    src={logo}
                    alt="Samecon"
                  />
                </Link>
              </div>

              <div className="header__right">
                <nav
                  id="main-nav"
                  className={`main-nav ${menuActive ? "active" : ""}`}
                  style={{ backgroundColor: 'white' }}
                >
                  <ul id="menu-primary-menu" className="menu" style={{height:'2vh', color: 'black' }}>
                    {renderMenuItems(menus)}
                  </ul>
                </nav>
                <div
                  className={`mobile-button ${menuActive ? "active" : ""}`}
                  onClick={handleMenuActive}
                  style={{ color: 'black' }} 
                >
                  <span style={{ backgroundColor: 'black' }}></span>
                </div>
              </div>

              <div className="header__action">
                <Link 
                  to="/contact" 
                  className="action-btn"
                  style={{
                    color: 'white',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    textDecoration: 'none'
                  }}
                >
                  <span>Contact</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;