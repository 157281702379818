import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../config/config";

Project.propTypes = {
  data: PropTypes.array.isRequired,
};

function Project(props) {
  const { data } = props;
  const [products, setProducts] = useState([]);
  const [dataBlock] = useState({
    subheading: "PRODUCTS",
    heading: "OUR PRODUCTS",
  });

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsSnapshot = await getDocs(collection(db, "products"));
        const fetchedProducts = productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const sortedProducts = fetchedProducts.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
        setProducts(sortedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  return (
    <section className="project">
      <div style={{ width: "100%" }} className="row">
        <div className="col-12">
          <div className="block-text center">
            <h6 className="sub-heading">
              <span>{dataBlock.subheading}</span>
            </h6>
            <h3 className="heading">{dataBlock.heading}</h3>
          </div>

          {products.length > 0 && (
            <Swiper
              key={products.length}
              className="project-swiper"
              spaceBetween={10}
              breakpoints={{
                0: { slidesPerView: 1 },
                768: { slidesPerView: Math.min(2, products.length) },
                991: { slidesPerView: Math.min(3, products.length) },
              }}
              loop={products.length > 1}
              modules={[Navigation, Pagination, Autoplay]}
              navigation={products.length > 1}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              autoplay={
                products.length > 1
                  ? {
                      delay: 0,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true,
                    }
                  : false
              }
              speed={3200}
              onSwiper={(swiper) => {
                if (swiper) {
                  setTimeout(() => {
                    swiper.update();
                    swiper.autoplay.start();
                  }, 0);
                }
              }}
            >
              {products.map((product) => (
                <SwiperSlide key={product.id} className="swiper-slide">
                  <div className="project-box">
                    <div className="image">
                      <Link to="/collection">
                        <img
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "contain",
                          }}
                          src={product.mainImage}
                          alt={product.title}
                        />
                      </Link>
                    </div>
                    <div className="content">
                      <Link to="/collection" className="h5 title">
                        {product.title}
                      </Link>
                      <p>{product.description}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}

          <div
            className="container"
            style={{
              margin: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-40px",
            }}
          >
            {/* You can add a button or other content here if needed */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Project;