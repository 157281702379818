import React from "react";
import Team2 from "../team/Team2";
import dataGallery from "../../assets/fake-data/data-gallery";

function Gallery() {
  return (
    <div>
      <Team2 data={dataGallery} subheading="Gallery"/>
    </div>
  );
}

export default Gallery;
