import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../config/config";
import { Search, ChevronDown, ChevronRight, X } from "lucide-react";

Project3.propTypes = {
  data: PropTypes.array,
};

function Project3(props) {
  const [products, setProducts] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedTags, setExpandedTags] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productsSnapshot = await getDocs(collection(db, "products"));
        const fetchedProducts = productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProducts(fetchedProducts);

        const tagsSnapshot = await getDocs(collection(db, "tags"));
        const fetchedTags = tagsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setTags(fetchedTags);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setIsMenuOpen(false);
      }, 3000);
    }
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [isMenuOpen]);

  const toggleTag = (tagId) => {
    setSelectedTags(prev => {
      if (prev.includes(tagId)) {
        return prev.filter(id => id !== tagId);
      } else {
        return [...prev, tagId];
      }
    });
  };

  const toggleExpandTag = (tagId) => {
    setExpandedTags(prev => ({
      ...prev,
      [tagId]: !prev[tagId]
    }));
  };

  const clearTags = () => {
    setSelectedTags([]);
  };

  const filteredProducts = products.filter(product => 
    (selectedTags.length === 0 || selectedTags.some(tagId => product.tags.includes(tagId))) &&
    (searchTerm === "" || 
     product.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
     tags.some(tag => tag.name.toLowerCase().includes(searchTerm.toLowerCase()) && product.tags.includes(tag.id)))
  );

  const sortedTags = [...tags].sort((a, b) => a.name.localeCompare(b.name));

  const renderTags = (parentId = null, level = 0) => {
    return sortedTags
      .filter((tag) => tag.parentId === parentId)
      .map((tag) => {
        const isExpanded = expandedTags[tag.id] || false;
        const hasChildren = tags.some((t) => t.parentId === tag.id);
        const isSelected = selectedTags.includes(tag.id);

        return (
          <div key={tag.id} style={{ marginLeft: `${level * 16}px` }}>
            <div style={styles.tagRow}>
              {hasChildren && (
                <span 
                  onClick={() => toggleExpandTag(tag.id)} 
                  style={styles.expandIcon}
                >
                  {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
                </span>
              )}
              <button
                onClick={() => toggleTag(tag.id)}
                style={{
                  ...styles.tagButton,
                  ...(isSelected ? styles.tagButtonActive : {}),
                }}
              >
                {tag.name}
                {isSelected && (
                  <X size={14} style={styles.removeTagIcon} onClick={(e) => {
                    e.stopPropagation();
                    toggleTag(tag.id);
                  }} />
                )}
              </button>
            </div>
            {isExpanded && renderTags(tag.id, level + 1)}
          </div>
        );
      });
  };

  const groupProductsByCategory = () => {
    const groupedProducts = {};

    const groupRecursively = (parentId = null, level = 0) => {
      const currentLevelTags = tags.filter(tag => tag.parentId === parentId);
      const childrenGroups = [];

      currentLevelTags.forEach(tag => {
        const tagProducts = filteredProducts.filter(product => 
          product.tags.includes(tag.id) &&
          !product.tags.some(productTag => 
            tags.some(t => t.parentId === tag.id && t.id === productTag)
          )
        );

        const children = groupRecursively(tag.id, level + 1);

        if (tagProducts.length > 0 || children.length > 0) {
          groupedProducts[tag.id] = {
            id: tag.id,
            name: tag.name,
            level: level,
            products: tagProducts,
            children: children
          };
          childrenGroups.push(groupedProducts[tag.id]);
        }
      });

      return childrenGroups;
    };

    return groupRecursively();
  };

  const renderProductCard = (item) => (
    <div key={item.id} className="col-xl-3 col-md-6">
      <div className="nft-item">
        <div className="card-media">
          <Link to={`/product/${item.id}`}>
            <img src={item.mainImage} alt="product image" />
          </Link>
        </div>
        <div className="card-title" style={styles.cardTitle}>
          <Link to={`/product/${item.id}`} className="h5">
            {item.title}
          </Link>
        </div>
        <div className="meta-info" style={styles.description}>
          <div className="author">
            <div className="info">
              <p>{item.description}</p>
            </div>
          </div>
        </div>
        <div className="card-bottom style-explode">
          <div className="button-place-bid">
            <Link to={`/product/${item.id}`} className="sc-button">
              <span>View Details</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  const renderCategoryProducts = (category) => {
    return (
      <div key={category.id}>
        <h2 style={getHeadingStyle(category.level)}>{category.name}</h2>
        {category.products.length > 0 && (
          <div className="row">
            {category.products.map(renderProductCard)}
          </div>
        )}
        {category.children.map(renderCategoryProducts)}
      </div>
    );
  };

  const getHeadingStyle = (level) => {
    const baseFontSize = 3.6;
    const fontSizeDecrement = 0.7;
    const fontSize = Math.max(baseFontSize - (level * fontSizeDecrement), 1);
    
    return {
      fontSize: `${fontSize}rem`,
      fontWeight: 700 - (level * 100),
      color: `hsl(210, 29%, ${Math.max(20 + (level * 10), 50)}%)`,
      textTransform: 'capitalize',
      borderBottom: `${3 - level}px solid ${level === 0 ? '#3498db' : '#bdc3c7'}`,
      paddingBottom: '0.5rem',
      marginBottom: '2rem',
      marginTop: '3rem',
      letterSpacing: `${0.5 - (level * 0.1)}px`,
    };
  };

  const styles = {
    filterSearchContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: '2rem',
      flexWrap: 'wrap',
    },
    tagFilter: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
    },
    tagRow: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '0.5rem',
    },
    expandIcon: {
      cursor: 'pointer',
      marginRight: '0.5rem',
      color: '#3498db',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      transition: 'background-color 0.2s ease',
    },
    tagButton: {
      padding: '0.5rem 1rem',
      border: '1px solid #e0e0e0',
      borderRadius: '20px',
      background: 'white',
      color: '#34495e',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      textAlign: 'left',
      fontSize: '0.9rem',
      fontWeight: '500',
    },
    tagButtonActive: {
      backgroundColor: '#3498db',
      color: 'white',
      borderColor: '#3498db',
    },
    removeTagIcon: {
      marginLeft: '8px',
      cursor: 'pointer',
    },
    searchContainer: {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #e0e0e0',
      borderRadius: '20px',
      padding: '0.5rem 1rem',
      backgroundColor: 'white',
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    },
    searchInput: {
      border: 'none',
      outline: 'none',
      marginLeft: '0.5rem',
      fontSize: '0.9rem',
    },
    cardTitle: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      fontSize: '1.25rem',
      fontWeight: 'bold',
    },
    description: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      color: '#7f8c8d',
    },
    menuButton: {
      padding: '0.75rem 1.5rem',
      border: 'none',
      borderRadius: '30px',
      background: 'linear-gradient(135deg, #3498db, #2980b9)',
      color: 'white',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      marginBottom: '1rem',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      fontSize: '1rem',
      fontWeight: '600',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    menuContent: {
      display: 'none',
      position: 'absolute',
      backgroundColor: '#ffffff',
      minWidth: '280px',
      boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
      zIndex: 1,
      borderRadius: '12px',
      padding: '1rem',
      maxHeight: '400px',
      overflowY: 'auto',
    },
    menuContentOpen: {
      display: 'block',
    },
    clearButton: {
      padding: '0.75rem 1rem',
      border: 'none',
      borderRadius: '20px',
      background: 'linear-gradient(135deg, #e74c3c, #c0392b)',
      color: 'white',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      marginTop: '1rem',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      fontSize: '0.9rem',
      fontWeight: '600',
      width: '100%',
    },
  };

  const groupedProducts = groupProductsByCategory();

  return (
    <section className="nft">
      <div className="container">
        <div style={styles.filterSearchContainer}>
          <div ref={menuRef}>
            <button 
              style={styles.menuButton} 
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              Product Categories {isMenuOpen ? <ChevronDown size={18} style={{marginLeft: '10px'}} /> : <ChevronRight size={18} style={{marginLeft: '10px'}} />}
            </button>
            <div style={{
              ...styles.menuContent,
              ...(isMenuOpen ? styles.menuContentOpen : {})
            }}>
              {renderTags()}
              <button 
                onClick={clearTags} 
                style={styles.clearButton}
              >
                Clear All
              </button>
            </div>
          </div>
          <div style={styles.searchContainer}>
            <Search size={20} />
            <input
              type="text"
              placeholder="Search products or tags..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={styles.searchInput}
            />
          </div>
        </div>
  
        {groupedProducts.map(renderCategoryProducts)}
      </div>
    </section>
  );
}

export default Project3;