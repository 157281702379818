import React, { useState, useEffect } from "react";
import img1 from "./Fp.jpg";
const Finepitch = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowWidth < 768;

  const styles = {
    section: {
      backgroundColor: "white",
      padding: isMobile ? "10px" : "20px",
    },
    div: {
      textAlign: "center",
      padding: isMobile ? "10px" : "20px",
    },
    img1: {
      maxWidth: "100%",
      height: "auto",
    },
  };

  return (
    <section style={styles.section}>
      <div style={styles.div}>
      
        <img
          src={img1}
          alt="Indoor Full Color SMD Series"
          style={styles.img1}
        />
       
      </div>
    </section>
  );
};

export default Finepitch;
