import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Button from "../button/Button";
import p1 from '../../assets/images/about/Profile/1.png'
import p2 from '../../assets/images/about/Profile/2.png'
import p3 from '../../assets/images/about/Profile/3.png'
import p4 from '../../assets/images/about/Profile/4.png'
import p5 from '../../assets/images/about/Profile/5.png'

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const glow = keyframes`
  0% { box-shadow: 0 0 15px rgba(128, 0, 128, 0.7), 0 0 30px rgba(128, 0, 128, 0.5); }
  50% { box-shadow: 0 0 40px rgba(128, 0, 128, 0.9), 0 0 60px rgba(128, 0, 128, 0.7); }
  100% { box-shadow: 0 0 15px rgba(128, 0, 128, 0.7), 0 0 30px rgba(128, 0, 128, 0.5); }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 60vh;
  animation: ${float} 6s ease-in-out infinite, ${glow} 3s ease-in-out infinite;
  border-radius: 25px;
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;
  border: 5px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);

  &:hover {
    transform: scale(1.05);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(26, 0, 51, 0.5), rgba(26, 0, 51, 0));
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.5s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  img.active {
    opacity: 1;
  }
`;

const ImageTitle = styled.h3`
  margin-top: 15px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1.2rem;
  display: inline-block;
`;

function About() {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [p1, p2, p3, p4, p5];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 2500);
    return () => clearInterval(interval);
  }, []);

  const [dataBlock] = useState({
    subheading: "About us",
    heading: "Who we are:",
    desc1:
      "At Rajdhani Sign Store, we manufacture video wall screens, digital screens, fabric boxes, clip-on boards, sandwich boards, and LED modules. With five years of experience, we are also trusted wholesale suppliers of LED strips, SMPS, EV chargers, segmented displays, SPDs, and controllers.",
    desc2:
      "Our IGBT-based static voltage stabilizers and efficient EV chargers under the Rajdhani MFG brand support environmental protection and carbon footprint reduction. We prioritize client satisfaction with exceptional customer service.",
  });

  return (
    <section className="about">
      <div className="shape"></div>
      <div className="container">
        <div className="row rev">
          <div className="col-xl-6 col-md-12">
            <div className="about__right">
              <ImageWrapper>
                {images.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    alt={`Company Profile ${index + 1}`}
                    className={index === currentImage ? 'active' : ''}
                  />
                ))}
              </ImageWrapper>
              <div style={{textAlign: 'center', marginTop: '15px'}}>
                <ImageTitle>Company Profile</ImageTitle>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="block-text">
              <h6
                style={{
                  fontSize: "1.5rem",
                }}
                className="sub-heading"
              >
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading">{dataBlock.heading}</h3>
              <p style={{ fontSize: "1.1rem" }} className="mb-17">
                {dataBlock.desc1}
              </p>
              <p style={{ fontSize: "1.1rem" }} className="mb-26">
                {dataBlock.desc2}
              </p>
              <Button link="/contact" title="Contact Us" />
              <div style={{ marginTop: '5vh' }}></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;