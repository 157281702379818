
import img1 from '../images/icon/icon1.png'
import img2 from '../images/icon/icon2.png'
import img3 from '../images/icon/icon3.png'
import img4 from '../images/icon/icon4.png'


const dataBox = [

    {
        id: 1,
        img: img1,
        title: 'Huge Collection',
        desc: 'We offer an extensive range of solutions tailored to your needs.'
    },
    {
        id: 2,
        img: img2,
        title: 'High Quality',
        desc: ' Discover unparalleled excellence with our premium-grade offerings'
    },
    {
        id: 3,
        img: img3,
        title: 'Fast Delivery',
        desc: 'All of our solutions are delivered on time'
    },
    {
        id: 4,
        img: img4,
        title: 'Pocket Friendly',
        desc: ' We deliver cost-effective solutions without compromising on quality.'
    },

]

export default dataBox;