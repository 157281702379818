import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { MessageCircle, ChevronLeft, ChevronRight, Maximize2, X, ArrowLeft } from 'lucide-react';
import { db } from '../../config/config';
import Footer from '../footer/Footer';

const ProductDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isFullScreenMode, setIsFullScreenMode] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const docRef = doc(db, 'products', id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setProduct({ id: docSnap.id, ...docSnap.data() });
        } else {
          setError('Product not found');
        }
      } catch (err) {
        setError('Error fetching product');
        console.error('Error fetching product:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const handleContactClick = () => {
    navigate('/Checkout', { state: { productTitle: product.title } });
  };

  const nextImage = () => {
    setSelectedImageIndex((prevIndex) => 
      (prevIndex + 1) % (product.descriptionImages.length + 1)
    );
  };

  const prevImage = () => {
    setSelectedImageIndex((prevIndex) => 
      (prevIndex - 1 + product.descriptionImages.length + 1) % (product.descriptionImages.length + 1)
    );
  };

  if (loading) return <div className="flex items-center justify-center h-screen text-2xl font-light">Loading...</div>;
  if (error) return <div className="flex items-center justify-center h-screen text-2xl font-light text-red-500">{error}</div>;
  if (!product) return null;

  const allImages = [product.mainImage, ...product.descriptionImages];

  return (
    <div className="mt-20 min-h-screen flex flex-col bg-gray-100">
      <main className="flex-grow overflow-y-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <button 
            onClick={() => navigate('/collection')} 
            className="mb-6 inline-flex items-center text-purple-600 hover:text-purple-800 transition-colors duration-300"
          >
            <ArrowLeft size={20} className="mr-2" />
            Back to Collection
          </button>

          <div className="bg-white rounded-3xl shadow-lg overflow-hidden">
            {/* Main Image - Maintaining aspect ratio */}
            <div className="relative w-full pt-[56.25%]"> {/* 16:9 Aspect Ratio */}
              <img 
                src={allImages[selectedImageIndex]}
                alt={product.title}
                className="absolute top-0 left-0 w-full h-full object-contain"
              />
              <button 
                onClick={() => setIsFullScreenMode(true)}
                className="absolute bottom-4 right-4 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-all duration-300"
                aria-label="View full size image"
              >
                <Maximize2 size={20} />
              </button>
            </div>

            {/* Thumbnails - Scrollable container */}
            <div className="p-4 overflow-x-auto">
              <div className="flex space-x-2 min-w-max">
                {allImages.map((image, index) => (
                  <div 
                    key={index}
                    className={`flex-shrink-0 w-24 h-24 rounded-lg overflow-hidden cursor-pointer ${
                      index === selectedImageIndex ? 'ring-2 ring-purple-500' : ''
                    }`}
                    onClick={() => setSelectedImageIndex(index)}
                  >
                    <img 
                      src={image}
                      alt={`Thumbnail ${index}`}
                      className="w-full h-full object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* Product Details */}
            <div className="p-6 lg:p-8">
              <h1 className="text-3xl lg:text-4xl font-bold text-gray-900 mb-4 capitalize">{product.title}</h1>
              <p className="text-base lg:text-lg text-gray-600 mb-6">{product.description}</p>
              
              <button 
                onClick={handleContactClick}
                className="inline-flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600 text-white px-6 py-3 rounded-full font-semibold text-lg transition-all duration-300 hover:from-purple-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 shadow-md hover:shadow-lg transform hover:-translate-y-1"
              >
                <MessageCircle size={20} className="mr-2" />
                Contact Now
              </button>
            </div>
          </div>
        </div>
      </main>

      {isFullScreenMode && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50" 
          onClick={() => setIsFullScreenMode(false)}
        >
          <div className="relative w-full h-full flex items-center justify-center">
            <img 
              src={allImages[selectedImageIndex]} 
              alt={`Full size view of ${selectedImageIndex === 0 ? 'main image' : `description ${selectedImageIndex}`}`} 
              className="max-w-full max-h-full w-auto h-auto object-contain"
            />
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-50 text-white px-4 py-2 rounded-full">
              {selectedImageIndex === 0 ? 'Main Image' : `Description ${selectedImageIndex}`}
            </div>
            <button 
              className="absolute top-4 right-4 text-white bg-black bg-opacity-50 p-2 rounded-full hover:bg-opacity-75 transition-all duration-300"
              onClick={(e) => { e.stopPropagation(); setIsFullScreenMode(false); }}
              aria-label="Close full size image view"
            >
              <X size={24} />
            </button>
          </div>
          <button 
            onClick={(e) => { e.stopPropagation(); prevImage(); }}
            className="absolute top-1/2 left-4 -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 text-gray-800 p-2 rounded-full transition-all duration-300"
            aria-label="Previous image"
          >
            <ChevronLeft size={24} />
          </button>
          <button 
            onClick={(e) => { e.stopPropagation(); nextImage(); }}
            className="absolute top-1/2 right-4 -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 text-gray-800 p-2 rounded-full transition-all duration-300"
            aria-label="Next image" 
          >
            <ChevronRight size={24} />
          </button>
        </div>
      )}
      
      <Footer />
    </div>
  );
};

export default ProductDetails;