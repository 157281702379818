import React , {useState} from 'react';

import PageTitle from '../components/pagetitle/PageTitle';
import Faqs from '../components/faqs/Faqs';
import Footer from '../components/footer/Footer';
import dataFaqs from '../assets/fake-data/data-faq';

import img from '../assets/images/layouts/map.png'
import img1 from '../assets/images/layouts/par-01.png'
import img2 from '../assets/images/layouts/par-02.png'
import img3 from '../assets/images/layouts/par-03.png'
import { Link } from 'react-router-dom';

function ParticipantAssets(props) {

    const [data] = useState([
        {
            id: 1,
            img: img1,
        },
        {
            id: 2,
            img: img2,
        },
        {
            id: 3,
            img: img3,
        }
    ])
    return (
        <div className='wrapper'>

            <PageTitle title='Participants & Assets' />

            <section className="participants">
                <div className="container">
                <h1>hello</h1>
                </div>
            </section>

            <Faqs data={dataFaqs} />

            <Footer />
            
        </div>
    );
}

export default ParticipantAssets;