import React from 'react'
import dataBox from '../assets/fake-data/data-box';
import Banner from '../components/banner/Banner';
import About from '../components/about/About';
import Speciality from '../components/speciality/Speciality';
import dataProject from '../assets/fake-data/dataProject';
import Project from '../components/project/Project';
import Team2 from '../components/team/Team2';
import Contact from './Contact'
import dataTeam from '../assets/fake-data/dataTeam';
function Main() {
  return (
   <div>
    <Banner />
    <About />
    <Project data={dataProject} />
    <Speciality data={dataBox} />
    {/* <Team2 data={dataTeam} subheading="Our Clients" /> */}
    <Contact />
   </div>
  )
}

export default Main