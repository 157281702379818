// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAggYIVouAQwn2v-fC8ohzu-thFjV6G-Rw",
  authDomain: "rajdhani-sign-backend.firebaseapp.com",
  projectId: "rajdhani-sign-backend",
  storageBucket: "rajdhani-sign-backend.appspot.com",
  messagingSenderId: "525865467764",
  appId: "1:525865467764:web:a4da4c0669f12eb060b621"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };



