import { db } from '../../config/config';
import { collection, getDocs } from "firebase/firestore";

const fetchData = async () => {
  try {
    const productsSnapshot = await getDocs(collection(db, "products"));
    const products = productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return products;
  } catch (error) {
    console.error("Error fetching products:", error);
    return [];
  }
};

const dataItem = await fetchData();
export default dataItem;