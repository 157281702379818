import React, { useEffect } from 'react';
import AOS from 'aos';
import { useLocation, Route, Routes } from 'react-router-dom';
import routes from './pages';
import Page404 from './pages/404';
import Header from './components/header/Header';
import ReactGa from 'react-ga4';

const TRACKING_ID = 'G-WTCPP6NKX2';
ReactGa.initialize(TRACKING_ID);

function App() {
  const location = useLocation();
  let startTime = Date.now();

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    ReactGa.send({ hitType: 'pageview', page: location.pathname });

    return () => {
      const timeSpent = Date.now() - startTime;
      ReactGa.send({
        hitType: 'timing_complete',
        name: 'load',
        value: timeSpent,
        eventCategory: 'Timing',
        eventAction: 'User Time Spent',
      });
    };
  }, [location]);

  return (
    <div className="app-container">
      <Header />
      <main className="content">
        <Routes>
          {routes.map((data, idx) => (
            <Route key={idx} path={data.path} element={data.component} exact />
          ))}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </main>
      <style jsx global>{`
        html, body {
          margin: 0;
          padding: 0;
          overflow-x: hidden;
        }
        .app-container {
          overflow-x: hidden;
          display: flex;
          flex-direction: column;
          min-height: 100vh;
        }
        .content {
          flex: 1;
          padding-top: var(--header-height, 80px); /* Adjust this value based on your header's height */
        }
      `}</style>
    </div>
  );
}

export default App;