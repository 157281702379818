import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { Send, ChevronLeft } from "lucide-react";
import { db } from "../../config/config";
import Footer from "../footer/Footer";

const API_KEY = "rPwMNVMhQ4DA8ni6IU2T5j8xGUs95l6k";

const PincodeForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const productTitle = location.state?.productTitle || "";

  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    phoneNumber: "",
    emailAddress: "",
    streetAddress: "",
    city: "",
    stateProvinceRegion: "",
    postalZipCode: "",
    quantity: 1,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [branches, setBranches] = useState([]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    fetchBranches();
  }, []);

  const fetchBranches = async () => {
    try {
      const branchesSnapshot = await getDocs(collection(db, "branches"));
      const branchesData = branchesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBranches(branchesData);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const geocode = async (pincode) => {
    try {
      const response = await fetch(
        `https://api.tomtom.com/search/2/geocode/${pincode}.json?key=${API_KEY}&country=IN`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        return data.results[0].position;
      }
      throw new Error("Location not found");
    } catch (error) {
      console.error("Geocoding error:", error);
      throw error;
    }
  };

  const calculateRoute = async (start, end) => {
    try {
      const response = await fetch(
        `https://api.tomtom.com/routing/1/calculateRoute/${start.lat},${start.lon}:${end.lat},${end.lon}/json?key=${API_KEY}`
      );
      const data = await response.json();
      return data.routes[0].summary.lengthInMeters;
    } catch (error) {
      console.error("Routing error:", error);
      throw error;
    }
  };

  const findNearestBranch = async (userCoords) => {
    let nearestBranch = null;
    let shortestDistance = Infinity;

    for (const branch of branches) {
      try {
        const branchCoords = await geocode(branch.pincode);
        const distance = await calculateRoute(userCoords, branchCoords);
        if (distance < shortestDistance) {
          shortestDistance = distance;
          nearestBranch = { ...branch, distance };
        }
      } catch (error) {
        console.error(`Error processing branch ${branch.id}:`, error);
      }
    }

    return nearestBranch;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");
    setProgress(0);

    try {
      setProgress(20);
      const userCoords = await geocode(formData.postalZipCode);
      setProgress(40);
      const nearestBranch = await findNearestBranch(userCoords);
      setProgress(60);

      if (nearestBranch) {
        const currentDate = new Date();
        const docRef = await addDoc(collection(db, "orders"), {
          ...formData,
          productTitle,
          branchId: nearestBranch.id,
          branchLocation: nearestBranch.location,
          roadDistance: (nearestBranch.distance / 1000).toFixed(2),
          createdAt: currentDate,
          read: false,
        });
        setProgress(80);
        console.log("Order added with ID: ", docRef.id);
        setSuccess(
          `Order placed for ${productTitle} in ${nearestBranch.location}`
        );
        setFormData({
          fullName: "",
          companyName: "",
          phoneNumber: "",
          emailAddress: "",
          streetAddress: "",
          city: "",
          stateProvinceRegion: "",
          postalZipCode: "",
          quantity: 1,
        });
        setProgress(100);
        setTimeout(() => {
          navigate("/Collection");
        }, 3000);
      } else {
        setError("Unable to find a nearest branch. Please try again.");
      }
    } catch (error) {
      console.error("Error adding order:", error);
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="mt-10 about">
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        maxHeight: '100vh',
        overflow: 'hidden',
      }}>
        <div style={{
          flex: 1,
          overflowY: 'auto',
          padding: '16px',
          backgroundColor: "#f9fafb",
        }}>
          <button
            onClick={() => navigate("/Collection")}
            style={{
              display: "inline-flex",
              alignItems: "center",
              color: "#4b5563",
              marginBottom: "16px",
              background: "none",
              border: "none",
              cursor: "pointer",
              fontSize: "14px",
            }}
          >
            <ChevronLeft size={20} />
            <span style={{ marginLeft: "8px" }}>Back to Products</span>
          </button>

          <div style={{
            backgroundColor: "white",
            borderRadius: "12px",
            padding: "24px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}>
            <h2 style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "16px", color: "#111827" }}>Checkout</h2>
            {productTitle && (
              <p style={{ fontSize: "16px", color: "#4b5563", marginBottom: "24px" }}>Product: {productTitle}</p>
            )}
            <form onSubmit={handleSubmit}>
              {[
                { name: "fullName", label: "Full Name", type: "text", required: true },
                { name: "companyName", label: "Company Name (optional)", type: "text" },
                { name: "phoneNumber", label: "Phone Number", type: "tel", required: true },
                { name: "emailAddress", label: "Email Address (optional)", type: "email" },
                { name: "streetAddress", label: "Street Address", type: "text", required: true },
                { name: "city", label: "City", type: "text", required: true },
                { name: "stateProvinceRegion", label: "State/Province/Region", type: "text", required: true },
                { name: "postalZipCode", label: "Postal/ZIP Code", type: "text", required: true },
                { name: "quantity", label: "Quantity", type: "number", required: true, min: 1 },
              ].map((field) => (
                <div key={field.name} style={{ marginBottom: "16px" }}>
                  <label htmlFor={field.name} style={{ display: "block", marginBottom: "4px", fontSize: "14px", fontWeight: "500", color: "#374151" }}>
                    {field.label}
                  </label>
                  <input
                    id={field.name}
                    name={field.name}
                    type={field.type}
                    value={formData[field.name]}
                    onChange={handleInputChange}
                    required={field.required}
                    min={field.min}
                    style={{
                      width: "100%",
                      padding: "8px 12px",
                      border: "1px solid #d1d5db",
                      borderRadius: "4px",
                      fontSize: "16px",
                    }}
                  />
                </div>
              ))}
              <button
                type="submit"
                disabled={loading}
                style={{
                  width: "100%",
                  backgroundColor: "#2563eb",
                  color: "white",
                  padding: "12px",
                  borderRadius: "9999px",
                  border: "none",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {loading ? (
                  "Processing..."
                ) : (
                  <>
                    <Send size={20} style={{ marginRight: "8px" }} /> Place Order
                  </>
                )}
              </button>
            </form>
            {error && <div style={{ color: "#ef4444", marginTop: "16px", textAlign: "center" }}>{error}</div>}
            {success && <div style={{ color: "#10b981", marginTop: "16px", textAlign: "center" }}>{success}</div>}
            {loading && (
              <div style={{ marginTop: "16px" }}>
                <div style={{ width: "100%", backgroundColor: "#e5e7eb", borderRadius: "9999px", height: "8px" }}>
                  <div
                    style={{
                      width: `${progress}%`,
                      backgroundColor: "#2563eb",
                      height: "100%",
                      borderRadius: "9999px",
                      transition: "width 0.3s ease-in-out",
                    }}
                  />
                </div>
                <p style={{ textAlign: "center", marginTop: "8px", fontSize: "14px", color: "#4b5563" }}>
                  {progress}% Complete
                </p>
              </div>
            )}
          </div>
        <Footer />
        </div>
      </div>
    </section>
  );
};

export default PincodeForm;