
import img1 from '../images/layouts/project-01.png'
import img2 from '../images/layouts/project-02.png'
import img3 from '../images/layouts/project-03.png'
import img4 from '../images/layouts/project-04.png'
import img5 from '../images/layouts/project-05.png'

const dataProject = [

    {
        id: 1,
        img: img1,
        title: 'Lorem Ipsum'
    },
    {
        id: 2,
        img: img2,
        title: 'Lorem Ipsum'
    },
    {
        id: 3,
        img: img3,
        title: 'Lorem Ipsum'
    },
    {
        id: 4,
        img: img4,
        title: 'Lorem Ipsum'
    },
    {
        id: 5,
        img: img5,
        title: 'Lorem Ipsum'
    },
    {
        id: 6,
        img: img1,
        title: 'Lorem Ipsum'
    },
    {
        id: 7,
        img: img2,
        title: 'Lorem Ipsum'
    },
    {
        id: 8,
        img: img3,
        title: 'Lorem Ipsum'
    },
    {
        id: 9,
        img: img4,
        title: 'Lorem Ipsum'
    },


    

]

export default dataProject;