import React from "react";
import Team2 from "../team/Team2";
import dataBranches from "../../assets/fake-data/data-branches";

function Branches() {
  return (
    <div>
      <Team2 data={dataBranches} subheading="Our Branches"/>
    </div>
  );
}

export default Branches;
