


const dataFaqs = [
    {
        id: 1,
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing e?',
        text : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Tellus aliquam parturient erat id vel, condimentum a,hendrerit egestas. Auctor cras diam, dui pulvinar elit. Egestas feugiat gravida in imperdiet facilisi tortor ac ultrices venenatis.',
        show: ''
    
    },
    {
        id: 2,
        title: ' Lorem ipsum dolor sit amet, consectetur adipiscing e?',
        text : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Tellus aliquam parturient erat id vel, condimentum a,hendrerit egestas. Auctor cras diam, dui pulvinar elit. Egestas feugiat gravida in imperdiet facilisi tortor ac ultrices venenatis.',
        show: ''
    
    },
    {
        id: 3,
        title: ' Lorem ipsum dolor sit amet, consectetur adipiscing?',
        text : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Tellus aliquam parturient erat id vel, condimentum a,hendrerit egestas. Auctor cras diam, dui pulvinar elit. Egestas feugiat gravida in imperdiet facilisi tortor ac ultrices venenatis.',
        show: ''
    
    },
    {
        id: 4,
        title: 'Lorem ipsum dolor sit amet?',
        text : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Tellus aliquam parturient erat id vel, condimentum a,hendrerit egestas. Auctor cras diam, dui pulvinar elit. Egestas feugiat gravida in imperdiet facilisi tortor ac ultrices venenatis.',
        show: ''
    
    },
    {
        id: 5,
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing e?',
        text : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Tellus aliquam parturient erat id vel, condimentum a,hendrerit egestas. Auctor cras diam, dui pulvinar elit. Egestas feugiat gravida in imperdiet facilisi tortor ac ultrices venenatis.',
        show: ''
    
    },
    {
        id: 6,
        title: ' Lorem ipsum dolor sit?',
        text : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Tellus aliquam parturient erat id vel, condimentum a,hendrerit egestas. Auctor cras diam, dui pulvinar elit. Egestas feugiat gravida in imperdiet facilisi tortor ac ultrices venenatis.',
        show: ''
    
    },
    {
        id: 7,
        title: ' Lorem ipsum dolor sit amet, consectetur adipiscing',
        text : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Tellus aliquam parturient erat id vel, condimentum a,hendrerit egestas. Auctor cras diam, dui pulvinar elit. Egestas feugiat gravida in imperdiet facilisi tortor ac ultrices venenatis.',
        show: ''
    
    },
    {
        id: 8,
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
        text : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Tellus aliquam parturient erat id vel, condimentum a,hendrerit egestas. Auctor cras diam, dui pulvinar elit. Egestas feugiat gravida in imperdiet facilisi tortor ac ultrices venenatis.',
        show: ''
    
    },


   

   
]

export default dataFaqs;